import React from 'react'
import CardProject from '../CardProject/CardProject'


const ProjectsList = ({ data }) => {
    return (
        <>
            {
                data.map((project, index) => {
                    return <CardProject key={index} nameProject={project.Name} descProject={project.Text} imageURL={project.ImagePath}/>
                })
            }
        </>
    )
}

export default ProjectsList