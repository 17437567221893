import React from 'react'
import './mainPage.css'
import HorizontalNavBar from '../CustomComponents/HorizontalNavBar/HorizontalNavBar'
import plan3 from '../../resources/plan3.jpeg'
import director from '../../resources/Directore.jpg'
import SliderImages from '../SliderImages/SliderImages'
import firstSlide from '../../resources/12f04c63.jpg'
import secondSlide from '../../resources/Project3.jpeg'
import thirdSlide from '../../resources/Project4.jpg'
import fourthSlide from '../../resources/Project5.jpg'
import fifthSlide from '../../resources/Project6.jpg'
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Footer from '../CustomComponents/Footer/Footer'

const MainPage = () => {
    const images = [
        { image: firstSlide },
        { image: secondSlide },
        { image: thirdSlide },
        { image: fourthSlide },
        { image: fifthSlide }
    ]

    return (
        <>
            <HorizontalNavBar />
            <SliderImages data={images} />
            <h2 className="name_company">Архитектурная компания «САРП» Махачкала</h2>
            <div className="company_description_block">
                <div class="image-container">
                    <img src={plan3} />
                </div>
                <div className="description_container">
                    <p className='description_company'>
                        Добро пожаловать в Архитектурную компанию «САРП» Махачкала. Мы — ведущая архитектурная
                        компания, специализирующаяся на создании инновационных и функциональных
                        решений для наших клиентов по всему миру. С момента нашего основания мы
                        зарекомендовали себя как надёжный партнёр, предлагающий
                        высококачественные услуги в области архитектуры, дизайна интерьеров и
                        инженерных систем. Наши услуги: Архитектурное проектирование: Мы создаём
                        уникальные концепции и детализированные планы, отвечающие всем
                        требованиям наших клиентов. Наш подход объединяет вдохновение и
                        инновации с практической реализацией. Дизайн интерьеров: Наши
                        специалисты внимательно прислушиваются к желаниям клиентов, создавая
                        интерьерные решения, которые отражают их индивидуальный стиль и
                        удовлетворяют функциональные потребности. Инженерные системы: Наша
                        команда инженеров обеспечивает проектирование и интеграцию передовых
                        технологий в области HVAC, электроснабжения и безопасности, гарантируя
                        эффективность и надёжность всех систем. Почему выбирают нас: Опыт и
                        экспертиза: Мы имеем многолетний опыт работы над проектами различного
                        масштаба и сложности. Индивидуальный подход: Мы стремимся понять
                        уникальные потребности каждого клиента и предложить индивидуальные
                        решения, соответствующие их целям и ожиданиям. Качество и сроки: Мы
                        гарантируем высокое качество выполнения работ и соблюдение сроков. Наши
                        проекты: Мы с гордостью представляем широкий спектр успешно завершённых
                        проектов в различных отраслях, включая жилые комплексы, коммерческие
                        здания, общественные пространства и многое другое. Каждый наш проект —
                        это результат сотрудничества с нашими клиентами и воплощения их мечтаний
                        в реальность. Свяжитесь с нами: Мы готовы помочь вам воплотить ваши
                        архитектурные и дизайнерские задумки в жизнь. Свяжитесь с нами сегодня,
                        чтобы обсудить ваш следующий проект и начать сотрудничество с [Название
                        вашей компании] Махачкала.
                    </p>
                </div>
            </div>
            <div className="dir_description_block">
                <div className="dir_image_container">
                    <img src={director} alt="Описание изображения" />
                </div>
                <div className="dir_description_container">
                    <h1 className='name_dir'>Магомедкамиль — Директор «САРП»</h1>
                    <p className='about_dir'>
                        Магомедкамиль — опытный лидер и вдохновляющий стратег, возглавляющий
                        Архитектурную компанию «САРП» Махачкала. С более чем [X] лет опыта в архитектуре и
                        управлении, [Имя] привносит уникальное сочетание креативного подхода и
                        стратегического мышления в каждый проект. Под его руководством компания
                        достигла значительных успехов, реализуя инновационные и востребованные
                        решения для клиентов. Магомедкамиль обладает глубокими знаниями в
                        области архитектурного проектирования и управления проектами, что
                        позволяет ему эффективно координировать команды и гарантировать высокий
                        стандарт качества работы. Его увлеченность инновациями и стремление к
                        совершенству вдохновляют коллектив и создают атмосферу творчества и
                        профессионализма. Под его руководством Архитекттурная копания продолжает
                        расширять горизонты, внедрять передовые технологии и устанавливать новые
                        стандарты в архитектуре и дизайне. Магомедкамиль активно участвует в
                        формировании стратегического направления компании и поддерживает тесные
                        отношения с клиентами, партнерами и сообществом.
                    </p>
                </div>
            </div>
            <h2 className="name_street">Наш офис находится на улице Абубакарова XX</h2>
            <div className="container_map">
                <YMaps>
                    <Map defaultState={{ center: [42.975976, 47.498079], zoom: 19 }} width={400} height={300}>
                        <Placemark geometry={[42.975976, 47.498079]} />
                    </Map>
                </YMaps>
            </div>
            <Footer />
        </>
    )
}

export default MainPage