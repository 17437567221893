import React from 'react';
import './newsPage.css';
import HorizontalNavBar from '../CustomComponents/HorizontalNavBar/HorizontalNavBar';
import CardNews from '../CardNews/CardNews';
import NewsList from '../NewsList/NewsList';
import useGetItems from '../../fetches/useGetItems.js';
import Footer from '../CustomComponents/Footer/Footer.jsx';

const NewsPage = () => {

    const { data, loading, error } = useGetItems("https://xn--80a4acc.xn--p1ai/getNews");

    return (
        <>
            <div className="news_page_block">
                <div className="content_news_block">
                    <HorizontalNavBar />
                    <NewsList data={data} />
                </div>
                <Footer/>
            </div>

        </>
    )
}

export default NewsPage