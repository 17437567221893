import React, { useState } from 'react';
import CardNews from '../../CardNews/CardNews';
import './search.css';

const Search = ({ data, onFilter, startData }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        
        setSearchTerm(event.target.value);

        const searchData = data.filter(item => {
            return item.Name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
        });

        const filteredResults = event.target.value.trim() === '' ? startData : searchData;

        onFilter(filteredResults)
    };

    return (
        <div className='search_block'>
            <input
                className='search_input'
                type="text"
                placeholder="Введите название проекта"
                value={searchTerm}
                onChange={handleInputChange}
            />
        </div>
    )
}

export default Search