import React from 'react'
import './horizontalNavBar.css'

const HorizontalNavBar = () => {
    return (
            <nav className="navbar" id="navbar">
                <div className="container">
                    <div className="logo">«САРП»</div>
                    <ul className="nav-links">
                        <li>
                            <a href="/">Главная</a>
                        </li>
                        <li>
                            <a href="/projects">Работы</a>
                        </li>
                        <li>
                            <a href="/news">Новости</a>
                        </li>
                    </ul>
                </div>
            </nav>
    )
}

export default HorizontalNavBar