import React, { useState } from 'react';
import './dropDownList.css';

const DropDownList = ({ data, onFilter, startData }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleFilter = (filterCriteria) => {
        const filteredResults = data.filter(item => {
            return item.Type === filterCriteria;
        });

        onFilter(filteredResults)
    };

    const dropFilters = () => {
        onFilter(startData)
    }

    return (
        <div className='dropdown'>
            <div className="dropdown_content">
                <button className='toggle' onClick={toggleDropdown}>Выберите тип из списка</button>
                <ul className={isOpen ? 'items_list_open' : 'items_list'}>
                    <button className='item' onClick={() => dropFilters()}>Без фильтров</button>
                    <button className='item' onClick={() => handleFilter('Школа')}>Школа</button>
                    <button className='item' onClick={() => handleFilter('Детский сад')}>Детский сад</button>
                    <button className='item' onClick={() => handleFilter('Многоэтажки')}>Многоэтажки</button>
                </ul>
            </div>
        </div>
    );
}

export default DropDownList