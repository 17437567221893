import React from 'react';
import './footer.css';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className='footerBack'>
            <div className="footer_content">
                <div className="phone_and_messengers">
                    <div className="contacts">
                        <div className='mail'>
                            Почта для связи: test@mail.ru
                        </div>
                        <div className='phoneNumber'>
                            Номер телефона: + 8 (999) 999-99-99
                        </div>
                    </div>
                    <div className='messangers'>
                        <a className='telegrammIcon' href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                            <FaTelegramPlane className='telegrammIcon' color="#0088cc" />
                        </a>
                        <a className='whatsapIcon' href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp className='whatsapIcon' color="#25D366" />
                        </a>
                    </div>
                </div>
            </div>


            {/* <div className="all_rights_block">
                All rights reserved. Terms & Conditions · Privacy Policy
            </div> */}
        </footer>
    )
}

export default Footer