import React from 'react';
import './sliderImages.css';
import Slider from 'react-animated-slider';


const SliderImages = ({ data }) => {

    return (
        <Slider classNames="slider" autoplay={3000}>
            {data.map((item, index) => (
                <div
                    key={index}
                    className="sliderContent"
                >
                    <img src={item.image} className='sliderImage'/>
                </div>
            ))}


        </Slider>
    )
}

export default SliderImages