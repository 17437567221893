import React from 'react'
import './cardProject.css'
import firstSlide from '../../resources/12f04c63.jpg'
import Slider from 'react-animated-slider';

const CardProject = ({ nameProject, descProject, imageURL }) => {
    return (
        <div className="wrapper">
            <div className="cardProject_slider_main">
                <Slider autoplay={3000} className='cardProject_slider'>
                    {
                        imageURL.map((item, index) => (
                            <div key={index} className='cardSlider_Content'>
                                <img src={item} alt='' className='cardPoject_img'/>
                            </div>
                        ))
                    }
                </Slider>
            </div>
            <div className="text-box">
                <h2>{nameProject}</h2>
                <p>{descProject}</p>
            </div>
        </div>
    )
}

export default CardProject