import React from 'react';
import './cardNews.css';

const CardNews = ({ nameNews, textNews, imageURL, cardDate }) => {
    return (
        <div className='news_block'>
            <img src={imageURL} className='image_news_block' />
            <div className='content_news_block'>
                <h1 className='title_news_block'>
                    {nameNews}
                </h1>
                <p className='text_news_block'>
                    {textNews}
                </p>
                <div className='date_news_block'>
                    {cardDate}
                </div>
            </div>
        </div>
    )
}

export default CardNews