import React from 'react'; 
import CardNews from '../CardNews/CardNews.jsx'

const NewsList = ({ data }) => {
    return (
        <>
            {
                data.map((news, index) => {
                    return <CardNews key={index} nameNews={news.Name} textNews={news.Text} imageURL={news.ImagePath} cardDate={news.Date}/>
                })
            }
        </>
    )
}

export default NewsList