import React, { useEffect, useState } from 'react'
import './projectsPage.css'
import HorizontalNavBar from '../CustomComponents/HorizontalNavBar/HorizontalNavBar'
import useGetItems from '../../fetches/useGetItems.js'
import ProjectsList from '../ProjectsList/ProjectsList.jsx'
import Filter from '../CustomComponents/Filter/Filter.jsx'
import Search from '../CustomComponents/Search/Search.jsx'
import Footer from '../CustomComponents/Footer/Footer.jsx'
import DropDownList from '../CustomComponents/DropDownList/DropDownList.jsx'

const ProjectsPage = () => {

    const { data, loading, error } = useGetItems("https://xn--80a4acc.xn--p1ai/getProjects");
    const originalData = data;

    const [filteredProjects, setFilteredProjects] = useState(data);

    useEffect(() => {
        setFilteredProjects(data);
    }, [data])

    const filterData = (criteria) => {
        setFilteredProjects(criteria);
    };

    return (
        <>
            <div className='projects_page_block'>
                <div className="content_projects_page">
                    <HorizontalNavBar />

                    <div className="filters">
                        <div className="filters_content">
                            <div className="type_project_dropdown">
                                <DropDownList data={data} onFilter={filterData} startData={originalData}/>
                            </div>

                            <div className="search_bloc">
                                <Search data={data} onFilter={filterData} startData={originalData} />
                            </div>
                        </div>
                    </div>

                    <ProjectsList data={filteredProjects} />

                </div>

                <Footer />
            </div>

        </>
    )
}

export default ProjectsPage